import slugify from 'slugify';
import { uniq } from 'lodash-es';
import { apolloClient } from '@/apollo';
import CUSTOM_TEMPLATE_ID from '@/graphql/CustomTemplateId.gql';
import FLYER_TEMPLATE_ID from '@/graphql/FlyerTemplateId.gql';
import GET_CHOOSER_FILTERED_TEMPLATES from '@/graphql/GetChooserFilteredTemplates.gql';
import GET_CHOOSABLE_THEME_CATEGORIES from '@/graphql/GetChoosableThemeCategories.gql';
import GET_POSSIBLE_FILTER_VALUES from '@/graphql/GetPossibleTemplateFilterValues.gql';
import GET_TEMPLATE_THEMES from '@/graphql/GetTemplateThemes.gql';
import GET_SORTED_SEASONS_WITH_BANNER from '@/graphql/GetSortedSeasonsWithBanner.gql';
import { sortSeasonalBackwards } from './useCase';

const LOADING = {
  THEMES: 'themes',
  FILTERED_TEMPLATES: 'filteredTemplates',
  SEASONAL_TEMPLATES: 'seasonalTemplates',
  FILTERS: 'filters',
  SEASONAL_BANNERS: 'seasonalBanners',
  RESELLER_TEMPLATES: 'resellerTemplates',
};

function upcomingCount() {
  const isPriorityPeriod = new Date().getMonth() >= 8; // Kiemelt időszak Szeptembertől december végéig
  return isPriorityPeriod ? 3 : 2;
}

const themeWithSlug = (theme) => ({
  ...theme,
  slug: `${slugify(theme.name)}-${theme._id}`,
});

const themeKitWithLogo = (theme) => ({
  ...theme,
  themeKit: {
    ...theme.themeKit,
    logo: theme?.logo?.current,
  },
});

const themeKitWithId = (theme) => ({
  ...theme,
  themeKit: {
    ...theme.themeKit,
    id: theme._id,
  },
});

export default {
  namespaced: true,
  state: {
    templateTypes: [],
    templateGoals: [],
    filter: {
      types: [],
      goals: [],
      contents: [],
    },
    filters: [],
    search: null,
    themeFamilies: [],
    themes: {
      user: [],
      base: [],
      seasons: [],
    },
    filteredTemplates: {
      byCategory: {},
      byTheme: {},
      searchResults: [],
    },
    seasonalTemplates: {},
    loading: {
      seasonalTemplates: false,
      filteredTemplates: false,
      themes: false,
      filters: false,
      resellerTemplates: false,
    },
    customTemplateId: {},
    flyerTemplateId: {},
    filterValues: null,
    baseThemeKitIds: [],
    seasonalBanners: [],
    resellerTemplates: [],
    displayedTemplates: [],
  },
  getters: {
    themes(state) {
      return state.themes;
    },
    seasonalBanners(state) {
      return state.seasonalBanners;
    },
    baseThemeKitIds(state) {
      return state.baseThemeKitIds;
    },
    loading(state) {
      return Object.values(state.loading).some((loading) => loading);
    },
    upcomingSeasons(state) {
      const count = upcomingCount();
      return (state.themes.seasons || []).slice(0, count);
    },
    templateResults(state) {
      let templates;
      if (state.filteredTemplates.searchResults?.length) {
        templates = state.filteredTemplates.searchResults;
      } else {
        templates = state.filteredTemplates.byTheme;
      }

      return Object.values(templates).flat();
    },
    seasonalsSortedBackwards(_, getters) {
      const copy = [...getters.templateResults];
      copy.sort(sortSeasonalBackwards);
      return copy;
    },
    useCasesWithTemplates(_, getters, __, rootGetters) {
      const useCaseMap = rootGetters['useCase/useCases'];
      const collection = {};

      const sortedTemplates = [...getters.templateResults].sort(sortSeasonalBackwards);
      sortedTemplates.forEach((template) => {
        const useCase = useCaseMap[template.useCase];
        if (!collection[template.useCase]) {
          collection[template.useCase] = {
            ...useCase,
            detailedTemplates: [template],
          };
        } else {
          collection[template.useCase].detailedTemplates.push(template);
        }
      });
      const sortedUseCases = rootGetters['useCase/sortedByScore'];

      return sortedUseCases.map((useCase) => collection[useCase._id]);
    },
    templatesByGoals(state, _, __, rootGetters) {
      const useCaseMap = rootGetters['useCase/useCases'];

      return Object.values(state.filteredTemplates.byTheme)
        .flat()
        .reduce((acc, template) => {
          const useCase = useCaseMap[template.useCase];
          useCase?.goals?.forEach((goal) => {
            if (acc[goal]) {
              acc[goal].push(template);
              acc[goal].sort((a, b) => {
                const aPopularity = a?.popularity ?? 0;
                const bPopularity = b?.popularity ?? 0;
                const isASeasonal = a?.categories?.length ?? [];
                const isBSeasonal = b?.categories?.length ?? [];

                if (isASeasonal && isBSeasonal) return 0;

                if (isASeasonal) return 1;
                if (isBSeasonal) return -1;

                if (a.favorite && b.favorite) return 0;

                if (a.favorite) return Number.MIN_SAFE_INTEGER;
                if (b.favorite) return Number.MAX_SAFE_INTEGER;

                return bPopularity - aPopularity;
              });
            } else {
              acc[goal] = [template];
            }
          });
          return acc;
        }, {});
    },
    lastUserTheme(_, getters) {
      return getters.userThemes[0] || null;
    },
    userThemes(state) {
      return state.themes.user.map((theme) => {
        let templates;

        const sourceTheme = theme.sourceTheme;
        if (state.filteredTemplates.searchResults?.length) {
          templates = state.filteredTemplates.searchResults.filter(
            (template) => template.template.themeKit?.id === sourceTheme,
          );
        } else {
          templates = state.filteredTemplates.byTheme[sourceTheme] || [];
        }

        const base = state.themes.base.find((theme) => theme._id === theme.sourceTheme);

        return {
          name: theme.name,
          templates,
          slug: `${slugify(theme.name)}-${theme._id}`,
          _id: theme._id,
          themeKit: theme.themeKit,
          base,
        };
      });
    },
    mergedThemes(state) {
      return [...state.themes.base, ...state.themes.user, ...state.themes.seasons];
    },
    resultCount(state, getters) {
      if (getters.loading) {
        return null;
      }

      const templatesSet = new Set();
      getters.templateResults.forEach((template) => templatesSet.add(template._id));

      Object.values(state.seasonalTemplates)
        .flat()
        .forEach((season) => {
          templatesSet.add(season._id);
        });

      return templatesSet.size;
    },
    filters(state) {
      const result = [];
      Object.entries(state.filter)
        .filter(([_, values]) => values.length)
        .forEach(([type, values]) => {
          values.forEach((value) => result.push({ type, value }));
        });
      return result;
    },
    displayedTemplates(state) {
      return Array.from(new Set(state.displayedTemplates));
    },
  },
  mutations: {
    SET_CUSTOM_TEMPLATE_ID(state, templateId) {
      state.customTemplateId = templateId;
    },
    SET_FLYER_TEMPLATE_ID(state, templateId) {
      state.flyerTemplateId = templateId;
    },
    SET_THEMES(state, themes) {
      state.themes = themes;
    },
    SET_FILTER_VALUES(state, filterValues) {
      state.filterValues = filterValues;
    },
    SET_BASE_THEMEKIT_IDS(state, baseThemeKitIds) {
      state.baseThemeKitIds = baseThemeKitIds;
    },
    SET_SEASONAL_TEMPLATES(state, seasonalThemes) {
      state.seasonalTemplates = seasonalThemes;
    },
    SET_LOADING(state, { type, value }) {
      state.loading[type] = value;
    },
    SET_SEARCH(state, value) {
      state.search = value;
    },
    SET_FILTERED_TEMPLATES(state, filteredTemplates) {
      state.filteredTemplates = filteredTemplates;
    },
    SET_THEME_FAMILIES(state, themeFamilies) {
      state.themeFamilies = themeFamilies;
    },
    SET_SEASONAL_BANNERS(state, banners) {
      state.seasonalBanners = banners;
    },
    SET_RESELLER_TEMPLATES(state, templates) {
      state.resellerTemplates = templates;
    },
    ADD_DISPLAYED_TEMPLATE(state, id) {
      state.displayedTemplates.push(`${id}`);
    },
    RESET_DISPLAYED_TEMPLATES(state) {
      state.displayedTemplates = [];
    },
  },
  actions: {
    async fetchCustomTemplateId({ commit }) {
      try {
        const { data } = await apolloClient.query({
          query: CUSTOM_TEMPLATE_ID,
        });
        commit('SET_CUSTOM_TEMPLATE_ID', data.customTemplateId);
      } catch (e) {
        console.error('Error fetching customTemplateId', e);
      }
    },
    async fetchFlyerTemplateId({ commit }) {
      try {
        const { data } = await apolloClient.query({
          query: FLYER_TEMPLATE_ID,
        });
        commit('SET_FLYER_TEMPLATE_ID', data.flyerTemplateId);
      } catch (e) {
        console.error('Error fetching flyerTemplateId', e);
      }
    },
    async fetchThemes({ commit, dispatch }) {
      commit('SET_LOADING', { type: LOADING.THEMES, value: true });
      try {
        const { data } = await apolloClient.query({
          query: GET_CHOOSABLE_THEME_CATEGORIES,
        });
        const { user, base, seasons } = data.themes;

        const themes = {
          user: user.map(themeWithSlug).map(themeKitWithLogo).map(themeKitWithId),
          base: base.map(themeWithSlug).map(themeKitWithLogo),
          seasons: seasons.map((s) => ({
            id: s,
            slug: s,
          })),
        };

        commit('SET_THEMES', themes);
        dispatch('setBaseThemeKitIds', themes.base);
      } catch (e) {
        console.error('Error fetching themes', e);
      }
      commit('SET_LOADING', { type: LOADING.THEMES, value: false });
    },
    async fetchTemplates(_, params) {
      try {
        const { data } = await apolloClient.query({
          query: GET_CHOOSER_FILTERED_TEMPLATES,
          variables: {
            ...params,
          },
        });

        return data.templates;
      } catch (e) {
        console.error('Error fetching templates', e);
      }
    },
    async fetchSeasonal({ state, getters, commit, dispatch, rootState }) {
      commit('SET_LOADING', { type: LOADING.SEASONAL_TEMPLATES, value: true });
      const filter = {
        ...state.filter,
        search: state.search,
        category: getters.upcomingSeasons.map(({ slug }) => slug),
      };
      try {
        const result = await dispatch('fetchTemplates', { filter, type: rootState.accountType });

        commit('SET_SEASONAL_TEMPLATES', result.byCategory);
      } catch (e) {
        console.error('Error fetching seasonal templates', e);
      }
      commit('SET_LOADING', { type: LOADING.SEASONAL_TEMPLATES, value: false });
    },
    async fetchFilteredTemplates({ state, commit, dispatch, rootState }) {
      commit('SET_LOADING', { type: LOADING.FILTERED_TEMPLATES, value: true });
      try {
        const filter = {
          filter: { ...state.filter, search: state.search },
          type: rootState.accountType,
        };
        const result = await dispatch('fetchTemplates', filter);

        commit('SET_FILTERED_TEMPLATES', result);
        commit('SET_LOADING', { type: LOADING.FILTERED_TEMPLATES, value: false });
      } catch (e) {
        console.error('Error fetching Filtered templates', e);
      }
    },

    async fetchResellerTemplates({ commit, dispatch, rootState }) {
      commit('SET_LOADING', { type: LOADING.RESELLER_TEMPLATES, value: true });
      const { byTheme = {}, byCategory = {} } = await dispatch('fetchTemplates', {
        filter: { types: [], goals: [], contents: [], search: null },
        type: rootState.accountType,
      });
      const templates = [...Object.values(byTheme).flat(), ...Object.values(byCategory).flat()];
      const unique = new Set(templates.map((tpl) => tpl._id));

      const resellerTemplates = Array.from(unique.values())
        .map((id) => templates.find((tpl) => tpl._id === id))
        .filter(
          (template) =>
            template.status === 'published' &&
            !!template.author &&
            !!template.author?.databaseId &&
            template.author.databaseId !== 44,
        );

      commit('SET_RESELLER_TEMPLATES', resellerTemplates);
      commit('SET_LOADING', { type: LOADING.RESELLER_TEMPLATES, value: false });
    },

    async fetchThemeFamilies({ commit }) {
      try {
        const { data } = await apolloClient.query({
          query: GET_TEMPLATE_THEMES,
        });

        commit('SET_THEME_FAMILIES', data.themes);
      } catch (e) {
        console.error('Error fetching themeFamilies', e);
      }
    },

    async fetchPossibleFilterValues({ state, commit }) {
      if (state.filterValues) return;
      commit('SET_LOADING', { type: LOADING.FILTERS, value: true });
      try {
        const {
          data: { values },
        } = await apolloClient.query({
          query: GET_POSSIBLE_FILTER_VALUES,
        });

        commit('SET_FILTER_VALUES', values);
        commit('SET_LOADING', { type: LOADING.FILTERS, value: false });
      } catch (e) {
        console.log("can't load filter values");
      }
    },

    async fetchSeasonalBanners({ state, commit }) {
      if (state.seasonalBanners.length) return;

      commit('SET_LOADING', { type: LOADING.SEASONAL_BANNERS, value: true });
      try {
        const {
          data: { result },
        } = await apolloClient.query({
          query: GET_SORTED_SEASONS_WITH_BANNER,
        });
        commit('SET_SEASONAL_BANNERS', result);
        commit('SET_LOADING', { type: LOADING.SEASONAL_BANNERS, value: false });
      } catch (error) {
        console.log("can't fetch seasonal banners");
      }
    },

    async setBaseThemeKitIds({ state, getters, commit }) {
      if (state.baseThemeKitIds?.length) return;
      const ids = getters.baseThemes?.map?.(({ _id }) => _id);
      commit('SET_BASE_THEMEKIT_IDS', ids ?? []);
    },
    addFilters({ state }, { type, values }) {
      const alreadyAdded = state.filter[type] || [];

      state.filter[type] = uniq([...alreadyAdded, ...values]);
    },
    async addFilter({ state }, { type, value }) {
      const alreadyAdded = state.filter[type].includes(value);
      if (alreadyAdded) return;

      state.filter[type].push(value);
    },

    async removeFilter({ state, dispatch }, { type, value }) {
      const selectedIndex = state.filter[type].indexOf(value);
      if (selectedIndex > -1) {
        state.filter[type].splice(selectedIndex, 1);
      }

      await Promise.all([dispatch('fetchFilteredTemplates'), dispatch('fetchSeasonal')]);
    },
    async resetFilters({ state }) {
      state.filters = [];
      state.filter = {
        types: [],
        goals: [],
        contents: [],
      };
    },
    async refetchWithFilterReset({ state, dispatch }) {
      state.filters = [];
      state.filter = {
        types: [],
        goals: [],
        contents: [],
      };
      state.search = null;
      await Promise.all([dispatch('fetchFilteredTemplates'), dispatch('fetchSeasonal')]);
    },
  },
};
