import CREATE_CAMPAIGN from '@/graphql/CreateCampaign.gql';
import { apolloClient } from '@/apollo';

const STEPS = ['template-preview', 'name-campaign', 'new-campaign-sms-prompt'];

export default {
  namespaced: true,
  state: {
    step: null,
    templateId: null,
    colors: [],
    theme: null,
    themeKit: null,
    needsSMSPrompt: false,
    isDynamicContent: false,
    addControlVariant: false,
    domainId: null,
    source: null,
    sourceUrl: null,
  },
  mutations: {
    initCampaignCreation(state, params) {
      state.templateId = params.templateId || null;
      state.colors = params.colors || [];
      state.theme = params.theme || undefined;
      state.needsSMSPrompt = params.needsSMSPrompt ?? false;
      state.isDynamicContent = params.isDynamicContent ?? false;
      state.addControlVariant = params.addControlVariant ?? false;

      state.domainId = null;
      state.source = null;
    },
    setThemeKit(state, themeKit) {
      state.themeKit = themeKit;
    },
    setDomainId(state, domainId) {
      state.domainId = domainId;
    },
    setSource(state, source) {
      state.source = source;
    },
    setSourceUrl(state, sourceUrl) {
      state.sourceUrl = sourceUrl;
    },
    setCurrentStep(state, step) {
      state.step = step;
    },
  },
  actions: {
    createCampaign({ state }, extraParams) {
      return apolloClient.mutate({
        mutation: CREATE_CAMPAIGN,
        variables: {
          input: {
            templateId: state.templateId,
            colors: state.colors,
            theme: state.theme,
            dynamicContent: state.isDynamicContent,
            addControlVariant: state.addControlVariant,
            domainId: state.domainId,
            source: state.source,
            ...extraParams,
          },
        },
      });
    },
  },
  getters: {
    previousCampaignCreationStep(state) {
      const currentStepIndex = STEPS.findIndex((step) => step === state.step);
      if (currentStepIndex > 0) {
        return STEPS[currentStepIndex - 1];
      }

      return null;
    },
  },
};
