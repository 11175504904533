import { get } from 'lodash-es';
import LogRocket from 'logrocket';
import { track } from '@/services/xray';

const HEAP_EVENT_NAME = 'editorInteraction';

const VALID_INTERACTIONS = [
  'settingChanged',
  'buttonClicked',
  'elementSelected',
  'elementAdded',
  'pageSelected',
  'drag',
];

const VALID_LOCATIONS = [
  'leftPane',
  'workspace',
  'toolbar',
  'rightPane',
  'editorHeader',
  'imageReplaceModal',
  'imageManager',
  'brandkit',
  'wizardBrandkit',
  'display',
];

const T_LOGS = {
  ERROR: 'error',
  INFO: 'info',
  DEBUG: 'debug',
  WARN: 'warn',
};

function log(type, ...args) {
  if (!T_LOGS.includes(type)) {
    console.warn(`[ANALYTICS/EDITOR] invalid log type: ${type}`);
    console.log('[ANALYTICS/EDITOR]', ...args);
    return;
  }

  console[type]('[ANALYTICS/EDITOR]', ...args);
}

function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getEditorLogRocketEventName(interactionType, properties = {}) {
  const { location = null, elementType = null } = properties;

  const nameParts = [location, elementType, interactionType].filter(Boolean);
  const concatenatedName = nameParts.map(ucFirst).join('');

  return `editor${concatenatedName}`;
}

function getElementType(selectedElementType, property) {
  let elementType;

  const isGlobalStyle = property?.startsWith?.('globalStyle.');
  const isColumn = property?.startsWith?.('selectedColumn.');
  const isRow = property?.startsWith?.('selectedRow.');
  const isPage = property?.startsWith?.('page.');
  const isTeaser = property?.indexOf?.('.tab.') > -1;

  if (property) {
    if (isTeaser) {
      elementType = 'Teaser';
    } else if (isGlobalStyle) {
      elementType = 'Overlay';
    } else if (isColumn) {
      elementType = 'Column';
    } else if (isRow) {
      elementType = 'Row';
    } else if (isPage) {
      elementType = 'Page';
    }

    if (elementType) return elementType;
  }

  return selectedElementType?.replace?.('Om', '') ?? 'OmTeaser';
}

const UPDATE_LAST_REPORTED_COLOR_TS = 'updateLastReportedColorTs';
const SET_LAST_REPORTED_COLOR_PROPERTY = 'setLastReportedColorProperty';

/**
 * - Egy közös `editorInteraction` eventben standardizáljuk az összes Editoros eventet, amiben a különböző propertyk adják a kontextust.
 * - Kötelező property:
 *   - `interactionType` (string) értékei:
 *   - `'settingChanged'` - korábban ‘test-ChangeEditorSetting’ event
 *   - `'buttonClicked'`
 *   - `'elementSelected'`
 *   - `'elementAdded'`
 *   - `'pageSelected'` stb.
 * - Általános kontextus property-k (ahol van értelme):
 *   - `location` (string) - ahol az interakció történt (`'rightPane'`, `'leftPane'`,  `'workspace'`, `'toolbar'` stb.)
 *   - `elementType` (string) - korábban ‘component’, (`'text'`, `'button'` stb.)
 *   - `collectedElementType` (string) - korábban ‘component’, (`'text'`, `'button'` stb.)
 *   - `pageIndex` (number)
 *   - `pageName` (string) - gond esetén `Unknown`
 *   - `device` (string) - `mobile` vagy `desktop` - gond esetén `Unknown`
 * - Interaction specifikus propertyk:
 *   - `settingName` (értéke ugyanaz mint most a test-ChangeEditorSetting event setting property-jénél)
 *   - `settingValue` (értéke ugyanaz mint most a test-ChangeEditorSetting event value property-jénél)
 *   - `buttonLabel` (`'emoji'`, `'alignLeft'` , `'fontWeight'` stb.)
 *   - `changeMethod` (’dragAndDrop’, ‘duplicate')
 */
export default {
  namespaced: true,
  state: {
    lastReportedColor: {
      ts: new Date('2000-01-01').getTime(),
      property: null,
    },
  },
  getters: {
    isSameColorProperty: (state) => (property) => {
      return state.lastReportedColor.property === property;
    },
    isSmallDifferenceBetweenColorReports: (state) => {
      return Date.now() - state.lastReportedColor.ts < 5000;
    },
  },
  mutations: {
    [UPDATE_LAST_REPORTED_COLOR_TS](state) {
      state.lastReportedColor.ts = Date.now();
    },
    [SET_LAST_REPORTED_COLOR_PROPERTY](state, property) {
      state.lastReportedColor.property = property;
    },
  },
  actions: {
    async reportEditorEvent(
      { dispatch, getters, commit },
      {
        interactionType,
        location,
        property = null,
        value = null,
        extras = {},
        collectContext = true,
        trackInLogrocket = true,
        logrocketEventName = null,
      } = {},
    ) {
      if (!VALID_INTERACTIONS.includes(interactionType)) {
        throw new Error(`[ANALYTICS/EDITOR] invalid interaction: ${interactionType}`);
      }

      if (!VALID_LOCATIONS.includes(location)) {
        throw new Error(`[ANALYTICS/EDITOR] invalid location: ${location}`);
      }

      if (property === 'omcolor') {
        log(T_LOGS.DEBUG, 'omcolor property, skip reporting');
        return;
      }

      if (property.indexOf('.color') > -1) {
        if (getters.isSameColorProperty(property) && getters.isSmallDifferenceBetweenColorReports) {
          log(T_LOGS.DEBUG, '.color property is same and small difference between reports');
          return;
        }

        commit(UPDATE_LAST_REPORTED_COLOR_TS);
        commit(SET_LAST_REPORTED_COLOR_PROPERTY, property);
      }

      let generalContext = { location };
      if (collectContext) {
        generalContext = await dispatch('collectEditorGeneralContext', { property, value });
      }

      const properties = {
        ...generalContext,
        ...extras,
      };

      log(T_LOGS.DEBUG, 'track sent', HEAP_EVENT_NAME, JSON.stringify(properties));
      dispatch('sendHeapEvent', { name: HEAP_EVENT_NAME, properties });

      if (trackInLogrocket) {
        const logRocketEventName =
          logrocketEventName ?? getEditorLogRocketEventName(interactionType, properties);
        dispatch('sendLogRocketEvent', { name: logRocketEventName, properties });
      }
    },
  },
  collectEditorGeneralContext({ property, value }) {
    let pageIndex = -1;
    let pageName = 'No page selected';

    const selectedPage = get(window.om?.store?.state, 'selectedPage', null) ?? null;

    if (selectedPage) {
      pageIndex = window.om.template.elements
        .filter((el) => el.type === 'OmPage')
        .findIndex((el) => el.uid === selectedPage.uid);
      pageName = get(selectedPage, 'data.title', 'No page title found');
    }

    let device = 'Unknown';
    if (window.om?.store?.state?.mobilePreview !== undefined) {
      device = window.om.store.state.mobilePreview ? 'mobile' : 'desktop';
    }

    property = property
      ?.replace?.('selectedElement.', '')
      ?.replace?.('globalStyle.', '')
      ?.replace?.('page.', '')
      ?.replace?.('style.', '')
      ?.replace?.('data.', '');

    return {
      elementType: getElementType(
        get(window.om?.store?.state, 'selectedElement.type') ?? undefined,
        property,
      ),
      pageIndex,
      pageName,
      device,
      settingName: property ?? undefined,
      settingValue: property ? value : undefined,
    };
  },
  sendHeapEvent(_, { name, properties }) {
    log(T_LOGS.DEBUG, 'track heap', name, JSON.stringify(properties));
    track(name, properties);
  },
  sendLogRocketEvent(_, { name, properties }) {
    log(T_LOGS.DEBUG, 'track logrocket', name, JSON.stringify(properties));
    LogRocket.track(name, properties);
  },
};
