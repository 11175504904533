export default {
  namespaced: true,
  state: {
    activeElement: null,
    visible: false,
    marginVisualizerActive: false,
    elementResizeActive: false,
  },
  getters: {
    isVisualizersActive(state) {
      return state.marginVisualizerActive || state.elementResizeActive;
    },
    hasActiveElement(state) {
      return state.activeElement;
    },
  },
  mutations: {
    SET_ACTIVE_ELEMENT(state, element) {
      state.activeElement = element;
    },
    SET_VISIBLE(state, visible) {
      state.visible = visible;
    },
    SET_MARGIN_VISUALIZER_ACTIVE(state, active) {
      state.marginVisualizerActive = active;
    },
    SET_ELEMENT_RESIZE_ACTIVE(state, active) {
      state.elementResizeActive = active;
    },
  },
  actions: {
    hideWysiwyg({ state, getters, commit }) {
      commit('SET_VISIBLE', false);
      if (getters.isVisualizersActive && state.activeElement) {
        commit(
          'setStateAttr',
          { attr: 'quillToolbarInfo', value: { show: false } },
          { root: true },
        );
      }
    },
    showWysiwyg({ getters, state, commit, dispatch }) {
      commit('SET_VISIBLE', true);
      if (state.activeElement && !getters.isVisualizersActive) {
        this._vm.$bus.$emit('deselectAll');
        const uid = state.activeElement;
        this._vm.$nextTick(() => {
          dispatch('selectElement', { uid }, { root: true });
        });
      }
    },
    updateWysiwyg({ commit }, { uid = null, visible = false } = {}) {
      commit('SET_ACTIVE_ELEMENT', uid);
      commit('SET_VISIBLE', visible);
    },
  },
};
